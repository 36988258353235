import React from "react";
import { ThemeProvider } from "styled-components";
import { graphql } from "gatsby";
import { RichText } from "prismic-reactjs";
import { lighten, rgba } from "polished";

import linkResolver from "src/utils/linkResolver";
import serializer from "src/utils/serializer";

import {
  PageHeaderV2,
  RibbonCTA,
  SEO,
  Theme,
  SectionWrapper,
  RichText as ComponentRichText,
} from "src/components";

import Packing from "./_components/_packing";
import Accommodations from "./_components/_accommodations";
import Venue from "./_components/_venue";
import Culture from "./_components/_culture";
import FAQ from "./_components/_faq";

function FestivalPlan({ data, setInvert, setInvertLogo }) {
  let festival = JSON.parse(data.festival.dataString);
  return (
    <>
      {/* // TEMP CHANGE FOR SUMMER SERENADES*/}
      <SEO
        title={"Plan Your Festival | " + RichText.asText(festival.title)}
        description={
          festival.opengraph_description &&
          RichText.asText(festival.opengraph_description)
        }
        image={
          festival.opengraph_image &&
          festival.opengraph_image.url &&
          festival.opengraph_image.url
        }
        imageAlt={
          festival.opengraph_image &&
          festival.opengraph_image.alt &&
          festival.opengraph_image.alt
        }
      />
      <ThemeProvider
        theme={Theme({
          bg:
            festival.theme === "Dark theme"
              ? {
                  default: "#1B1A19",
                  alt: lighten(0.05, "#1B1A19"),
                  wash: lighten(0.1, "#1B1A19"),
                  reverse: "#FFF",
                }
              : undefined,
          primary: festival.festival_color,
          secondary: festival.festival_secondary_color,
          tertiary: festival.festival_tertiary_color,
        })}
      >
        {festival.plan_header && festival.plan_header[0] && (
          <PageHeaderV2
            heading={
              festival.plan_header
                ? RichText.asText(festival.plan_header)
                : "Plan your Festival"
            }
            links={[
              festival.packing_title &&
                festival.packing_title[0] &&
                festival.packing_title[0].text,
              festival.culture_title &&
                festival.culture_title[0] &&
                festival.culture_title[0].text,
              festival.venue_title &&
                festival.venue_title[0] &&
                festival.venue_title[0].text,
              festival.accommodations_title &&
                festival.accommodations_title[0] &&
                festival.accommodations_title[0].text,
              festival.faq_title &&
                festival.faq_title[0] &&
                festival.faq_title[0].text,
            ]}
            image={festival.plan_hero_image}
            color="brand.primary"
            setInvert={setInvert}
            setInvertLogo={setInvertLogo}
            invert={true}
            illustrations={festival.illlustrations}
            sw={3}
            se={5}
            size={2.5}
          />
        )}
      </ThemeProvider>
      <ThemeProvider
        theme={Theme({
          text:
            festival.theme === "Dark theme"
              ? {
                  default: "#fff",
                  body: rgba("#fff", 0.75),
                  alt: rgba("#fff", 0.5),
                  reverse: "#1B1A19",
                  reverseBody: rgba("#1B1A19", 0.75),
                  reverseAlt: rgba("#1B1A19", 0.5),
                }
              : undefined,
          bg:
            festival.theme === "Dark theme"
              ? {
                  default: "#1B1A19",
                  alt: lighten(0.05, "#1B1A19"),
                  wash: lighten(0.1, "#1B1A19"),
                  reverse: "#FFF",
                }
              : undefined,
          primary: festival.festival_color,
          secondary: festival.festival_secondary_color,
          tertiary: festival.festival_tertiary_color,
        })}
      >
        <>
          {festival.plan_description && (
            <SectionWrapper
              pt={[4, 5, 6]}
              pb={[8, 9, 10]}
              maxWidth={0}
              bg="bg.default"
            >
              <ComponentRichText
                textAlign="center"
                fontSize={[1, 2]}
                color="text.body"
              >
                {RichText.render(
                  festival.plan_description,
                  linkResolver,
                  serializer
                )}
              </ComponentRichText>
            </SectionWrapper>
          )}
          {festival.packing_title &&
            RichText.asText(festival.packing_title) !== "" && (
              <Packing
                title={
                  festival.packing_title &&
                  RichText.asText(festival.packing_title)
                }
                description={
                  festival.packing_description &&
                  RichText.render(
                    festival.packing_description,
                    linkResolver,
                    serializer
                  )
                }
                packingItems={festival.packing_items}
              />
            )}
          {festival.culture_title &&
            RichText.asText(festival.culture_title) !== "" && (
              <Culture
                title={
                  festival.culture_title &&
                  RichText.asText(festival.culture_title)
                }
                description={
                  festival.culture_description &&
                  RichText.render(
                    festival.culture_description,
                    linkResolver,
                    serializer
                  )
                }
                rules={festival.rules}
              />
            )}

          {festival.venue_title &&
            RichText.asText(festival.venue_title) !== "" && (
              <Venue
                title={
                  festival.venue_title && RichText.asText(festival.venue_title)
                }
                description={
                  festival.venue_description &&
                  RichText.render(
                    festival.venue_description,
                    linkResolver,
                    serializer
                  )
                }
                subvenues={festival.subvenues}
                image1={festival.venue_image_1}
                image2={festival.venue_image_2}
                image3={festival.venue_image_3}
                image4={festival.venue_image_4}
                image5={festival.venue_image_5}
              />
            )}
          {festival.accommodations_title &&
            RichText.asText(festival.accommodations_title) !== "" && (
              <Accommodations
                title={
                  festival.accommodations_title &&
                  RichText.asText(festival.accommodations_title)
                }
                description={
                  festival.accommodations_description &&
                  RichText.render(
                    festival.accommodations_description,
                    linkResolver,
                    serializer
                  )
                }
                accommodations={festival.accommodations}
              />
            )}
          {festival.faq_title && RichText.asText(festival.faq_title) !== "" && (
            <FAQ
              heading={
                festival.faq_title && RichText.asText(festival.faq_title)
              }
              faqs={festival.faqs}
            />
          )}
          {festival.plan_cta_heading && festival.plan_cta_heading[0] && (
            <RibbonCTA
              heading={
                festival.plan_cta_heading &&
                RichText.asText(festival.plan_cta_heading)
              }
              body={festival.plan_cta_subheading}
              buttonText={festival.plan_cta_button_text}
              buttonLink={festival.plan_cta_button_link}
              color="brand.tertiary"
              invert={true}
            />
          )}
        </>
      </ThemeProvider>
    </>
  );
}

export default FestivalPlan;

export const query = graphql`
  query ($id: String!) {
    festival: prismicFestival(id: { eq: $id }) {
      dataString
    }
  }
`;
