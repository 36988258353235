import React from "react";
import { RichText as PrismicRichText } from "prismic-reactjs";

import { linkResolver, serializer } from "src/utils";
import {
  Accordion,
  Button,
  PrismicLink,
  PrismicImage,
  RichText,
  SectionHeader,
  SectionWrapper,
  Text
} from "src/components";

function Accommodations({ title, description, accommodations }) {
  return (
    <SectionWrapper
      id={title.replace(/\s/g, "-").toLowerCase()}
      py={[8, 9, 10]}
      bg="bg.reverse"
    >
      <SectionHeader heading={title} body={description} invert={true} />
      {accommodations && (
        <Accordion
          items={accommodations.map((accommodation, index) => {
            return {
              id: "hotel" + index,
              heading: accommodation.name,
              subheading: accommodation.starting_price
                ? `Starts at $${accommodation.starting_price}/night`
                : ``,
              image:
                accommodation.image &&
                accommodation.image.url &&
                accommodation.image,
              children: (
                <div
                  css={`
                    display: flex;
                    flex-direction: column;
                    ${props => props.theme.mediaQueries.small} {
                      flex-direction: row;
                    }
                  `}
                >
                  <div
                    css={`
                      flex: 1;
                      ${props => props.theme.mediaQueries.small} {
                        margin-right: 64px;
                      }
                    `}
                  >
                    <Text
                      children={accommodation.address}
                      mb={3}
                      color="text.reverseBody"
                    />
                    <RichText
                      children={PrismicRichText.render(
                        accommodation.description,
                        linkResolver,
                        serializer
                      )}
                      mb={5}
                      fontSize={[1, 2]}
                      color="text.reverseBody"
                    />
                    <Button
                      children="Book now"
                      variant="reverseAlt"
                      as={PrismicLink}
                      link={accommodation.link}
                    />
                  </div>
                  {accommodation.image &&
                    accommodation.image.url &&
                    accommodation.image && (
                      <div
                        css={`
                          width: 100%;
                          margin-top: ${props => props.theme.space[7] + "px"};
                          ${props => props.theme.mediaQueries.small} {
                            width: 33%;
                            margin-top: 0;
                          }
                        `}
                      >
                        <PrismicImage
                          image={
                            accommodation.image &&
                            accommodation.image.url &&
                            accommodation.image
                          }
                        />
                      </div>
                    )}
                </div>
              )
            };
          })}
          color="text.reverse"
        />
      )}
    </SectionWrapper>
  );
}

export default Accommodations;
