import React from "react";

import {
  Box,
  FlexGrid,
  PrismicImage,
  SectionHeader,
  SectionWrapper,
  Text
} from "src/components";

function Packing({ title, description, packingItems }) {
  return (
    <SectionWrapper
      id={title.replace(/\s/g, "-").toLowerCase()}
      py={[8, 9, 10]}
      bg="bg.default"
      css={`
        border-top: 1px solid ${props => props.theme.colors.bg.alt};
      `}
    >
      <SectionHeader heading={title} body={description} invert={false} />
      {packingItems && (
        <FlexGrid gutterX={[5]}>
          {packingItems.map((item, index) => (
            <Box
              width={[1 / 2, 1 / 3, null, 1 / 6]}
              key={"packingItems" + index}
              alignItems="center"
            >
              {item.image && (
                <PrismicImage
                  image={item.image}
                  imgStyle={{ objectFit: "contain" }}
                />
              )}
              <Text children={item.item} fontSize={0} textAlign="center" />
            </Box>
          ))}
        </FlexGrid>
      )}
    </SectionWrapper>
  );
}

export default Packing;
