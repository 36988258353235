import React from "react";
import { cover } from "polished";
import { RichText as PrismicRichText } from "prismic-reactjs";

import { linkResolver, serializer } from "src/utils";
import {
  Accordion,
  Button,
  PrismicLink,
  PrismicImage,
  RichText,
  SectionHeader,
  SectionWrapper
} from "src/components";

const gap = "32px";
const smallGap = "16px";

function Venue({
  title,
  description,
  subvenues,
  image1,
  image2,
  image3,
  image4,
  image5
}) {
  return (
    <>
      {title && (
        <SectionWrapper
          id={title.replace(/\s/g, "-").toLowerCase()}
          py={[8, 9, 10]}
          bg="bg.default"
        >
          <SectionHeader heading={title} body={description} invert={false} />
          {subvenues && (
            <Accordion
              items={subvenues.map((subvenue, index) => {
                return {
                  id: "subvenue" + index,
                  heading: subvenue.name,
                  subheading: subvenue.address,
                  image: subvenue.image && subvenue.image.url && subvenue.image,
                  children: (
                    <div
                      css={`
                        display: flex;
                        flex-direction: column;
                        ${props => props.theme.mediaQueries.small} {
                          flex-direction: row;
                        }
                      `}
                    >
                      <div
                        css={`
                          flex: 1;
                          ${props => props.theme.mediaQueries.small} {
                            margin-right: 64px;
                          }
                        `}
                      >
                        <RichText
                          children={PrismicRichText.render(
                            subvenue.description,
                            linkResolver,
                            serializer
                          )}
                          mb={5}
                          fontSize={[1, 2]}
                          color="text.body"
                        />
                        <Button
                          children="Learn more"
                          variant="primary"
                          as={PrismicLink}
                          link={subvenue.link}
                        />
                      </div>
                      {subvenue.image && subvenue.image.url && subvenue.image && (
                        <div
                          css={`
                            width: 100%;
                            margin-top: ${props => props.theme.space[7] + "px"};
                            ${props => props.theme.mediaQueries.small} {
                              width: 33%;
                              margin-top: 0;
                            }
                          `}
                        >
                          <PrismicImage
                            image={
                              subvenue.image &&
                              subvenue.image.url &&
                              subvenue.image
                            }
                          />
                        </div>
                      )}
                    </div>
                  )
                };
              })}
              color="text.default"
            />
          )}
          {((image1 && image1.url) ||
            (image2 && image2.url) ||
            (image3 && image3.url) ||
            (image4 && image4.url) ||
            (image5 && image5.url)) && (
            <div
              css={`
                overflow: hidden;
              `}
            >
              <div
                css={`
                  display: grid;
                  -ms-grid-columns: 1fr ${smallGap} 1fr ${smallGap} 1fr
                    ${smallGap} 1fr ${smallGap} 1fr ${smallGap} 1fr ${smallGap}
                    1fr ${smallGap} 1fr;
                  grid-template-columns: 1fr ${smallGap} 1fr ${smallGap} 1fr ${smallGap} 1fr ${smallGap} 1fr ${smallGap} 1fr ${smallGap} 1fr ${smallGap} 1fr;
                  -ms-grid-rows: 50vw ${smallGap} 50vw;
                  grid-template-rows: 50vw ${smallGap} 50vw;
                  ${props => props.theme.mediaQueries.medium} {
                    -ms-grid-columns: 1fr ${gap} 1fr ${gap} 1fr ${gap} 1fr
                      ${gap} 1fr ${gap} 1fr ${gap} 1fr ${gap} 1fr;
                    grid-template-columns: 1fr ${gap} 1fr ${gap} 1fr ${gap} 1fr ${gap} 1fr ${gap} 1fr ${gap} 1fr ${gap} 1fr;
                    -ms-grid-rows: minmax(320px, 15vw) ${gap}
                      minmax(320px, 15vw);
                    grid-template-rows: minmax(320px, 15vw) ${gap} minmax(
                        320px,
                        15vw
                      );
                  }
                `}
              >
                {image1 && (
                  <div
                    css={`
                      position: relative;
                      -ms-grid-column: 1;
                      -ms-grid-column-span: 5;
                      grid-column: 1 / 6;
                      -ms-grid-row: 1;
                      grid-row: 1;
                      ${props => props.theme.mediaQueries.medium} {
                        -ms-grid-column: 1;
                        -ms-grid-column-span: 3;
                        grid-column: 1 / 4;
                        -ms-grid-row: 1;
                        grid-row: 1;
                      }
                    `}
                  >
                    <PrismicImage
                      image={image1}
                      style={{ ...cover(), width: "100%", height: "100%" }}
                      sizes="250px"
                    />
                  </div>
                )}
                {image2 && (
                  <div
                    css={`
                      position: relative;
                      -ms-grid-column: 7;
                      -ms-grid-column-span: 9;
                      grid-column: 7 / 16;
                      -ms-grid-row: 1;
                      grid-row: 1;
                      ${props => props.theme.mediaQueries.medium} {
                        -ms-grid-column: 5;
                        -ms-grid-column-span: 7;
                        grid-column: 5 / 12;
                        -ms-grid-row: 1;
                        grid-row: 1;
                      }
                    `}
                  >
                    <PrismicImage
                      image={image2}
                      style={{ ...cover(), width: "100%", height: "100%" }}
                      sizes="420px"
                    />
                  </div>
                )}
                {image3 && (
                  <div
                    css={`
                      position: relative;
                      -ms-grid-column: 11;
                      -ms-grid-column-span: 5;
                      grid-column: 11 / 16;
                      -ms-grid-row: 3;
                      grid-row: 3;
                      ${props => props.theme.mediaQueries.medium} {
                        -ms-grid-column: 13;
                        -ms-grid-column-span: 3;
                        grid-column: 13 / 16;
                        -ms-grid-row: 1;
                        grid-row: 1;
                      }
                    `}
                  >
                    <PrismicImage
                      image={image3}
                      style={{ ...cover(), width: "100%", height: "100%" }}
                      sizes="250px"
                    />
                  </div>
                )}
                {image4 && (
                  <div
                    css={`
                      position: relative;
                      display: none;
                      ${props => props.theme.mediaQueries.medium} {
                        display: initial;
                        -ms-grid-column: 1;
                        -ms-grid-column-span: 9;
                        grid-column: 1 / 10;
                        -ms-grid-row: 3;
                        grid-row: 3;
                      }
                    `}
                  >
                    <PrismicImage
                      image={image4}
                      style={{ ...cover(), width: "100%", height: "100%" }}
                      sizes="600px"
                    />
                  </div>
                )}
                {image5 && (
                  <div
                    css={`
                      position: relative;
                      -ms-grid-column: 1;
                      -ms-grid-column-span: 9;
                      grid-column: 1 / 10;
                      -ms-grid-row: 3;
                      grid-row: 3;
                      ${props => props.theme.mediaQueries.medium} {
                        -ms-grid-column: 11;
                        -ms-grid-column-span: 5;
                        grid-column: 11 / 16;
                        -ms-grid-row: 3;
                        grid-row: 3;
                      }
                    `}
                  >
                    <PrismicImage
                      image={image5}
                      style={{ ...cover(), width: "100%", height: "100%" }}
                      sizes="420px"
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </SectionWrapper>
      )}
    </>
  );
}

export default Venue;
