import React from "react";

import {
  Box,
  FlexGrid,
  PrismicImage,
  SectionHeader,
  SectionWrapper,
  Text
} from "src/components";

function Culture({ title, description, rules }) {
  return (
    <>
      {title && (
        <SectionWrapper
          id={title.replace(/\s/g, "-").toLowerCase()}
          py={[8, 9, 10]}
          bg="bg.default"
          css={`
            border-top: 1px solid ${props => props.theme.colors.bg.alt};
          `}
        >
          <SectionHeader heading={title} body={description} invert={false} />
          {rules && (
            <FlexGrid gutterX={[5, 7]}>
              {rules.map((rule, index) => (
                <Box
                  width={[1 / 2, 1 / 4]}
                  alignItems="center"
                  key={"rule" + index}
                >
                  {rule.do_image && <PrismicImage image={rule.do_image} />}
                  <Text
                    children={rule.rule}
                    fontSize={0}
                    textAlign="center"
                    color="text.body"
                  />
                </Box>
              ))}
            </FlexGrid>
          )}
        </SectionWrapper>
      )}
    </>
  );
}

export default Culture;
