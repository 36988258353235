import React from "react";
import { RichText as PrismicRichText } from "prismic-reactjs";

import linkResolver from "src/utils/linkResolver";
import serializer from "src/utils/serializer";

import {
  Accordion,
  RichText,
  SectionHeader,
  SectionWrapper
} from "src/components";

const FAQ = ({ faqs, heading }) => (
  <SectionWrapper
    id={heading.replace(/\s/g, "-").toLowerCase()}
    py={[8, 9, 10]}
    bg="bg.wash"
  >
    <SectionHeader heading={heading} />
    <section>
      {faqs && (
        <Accordion
          items={faqs.map(faqItem => {
            return {
              id: faqItem.question,
              heading: faqItem.question,
              children: (
                <RichText
                  children={PrismicRichText.render(
                    faqItem.answer,
                    linkResolver,
                    serializer
                  )}
                  fontSize={[1, 2]}
                  color="text.body"
                />
              )
            };
          })}
          color="text.default"
        />
      )}
    </section>
  </SectionWrapper>
);

export default FAQ;
